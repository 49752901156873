// Override default variables before the import

@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&family=Ubuntu:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

$body-bg: #eee;
$top: #bbb;

body {
  font-family: "Ubuntu", Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
}

p,
td,
th,
div,
ul,
li {
  //   font-family: "Ubuntu Condensed", sans-serif;
  font-family: "Ubuntu", sans-serif;
}

h1 {
  text-transform: uppercase;
}

.day,
.month {
  font-size: 1em;
  font-weight: 300;
  line-height: 0.7em;
  text-transform: uppercase;
}
.date {
  font-size: 2em;
  line-height: 1.5em;
}
.time {
  font-size: 0.8em;
  background-color: #bbb;
  padding: 3px;
  margin-bottom: 5px;
}

.artist {
  font-size: 1em;
  color: #666;
  text-align: left;
}
.artistShow {
  font-size: 1.3em;
  color: #a50404;
  font-weight: bold;
  text-align: left;
}
.location {
  color: #666;
  text-align: left;
}
.locationAddress {
  font-size: 0.8em;
  color: #999;
  text-align: left;
}

.title {
  font-size: 1.3em;
  color: #a50404;
  font-weight: bold;
  text-align: left;
  margin-bottom: 15px;
}

.eventDetailTab {
  color: #666666 !important;
  font-size: 1.5em !important;
}

.communicationButton {
  text-align: left;
  margin-right: 10px;
  color: #a50404;
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
